<template>
  <div class="row">
    <BulkAction
      v-if="hasBulkAction"
      :bulkLoading="bulkLoading"
      :hasMultiSelect="hasMultiSelect"
      :exportLoading="exportLoading"
      :permissions="permissions"
      :refs="refs"
      :search="search"
      :date="date"
      :paid="paid"
      :status="status"
      :paginate="perPage"
      v-on:refreshTable="fetchData"
      :fields="fields"
      :selected="selected"
    >
    </BulkAction>

    <div class="col-8">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div
              class="col-sm-11"
              :style="$i18n.locale == 'ar' ? '' : 'text-align: left'"
            >
              <h5 :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'mrl-5'">
                {{ $t("app.all_rows") }}
                <span v-if="dataLoading">
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  >
                  </span>
                </span>
                <span v-if="!dataLoading">( {{ total }} )</span>
              </h5>

              <div
                v-if="hasSearchFilter"
                class="search-box me-2 mb-2 d-inline-block"
              >
                <div class="position-relative">
                  <form @submit.prevent="fetchData">
                    <input
                      type="text"
                      class="form-control"
                      :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                      :placeholder="lblSearch"
                      v-model="search"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </form>
                </div>
              </div>

              <div
                v-if="hasDateFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="date"
                    class="form-control"
                    v-model="date"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="date"
                      @click="clearDate"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-sm-1">
              <ShowEntries
                :perPage="this.perPage"
                v-on:showChange="handelShowChange"
              >
              </ShowEntries>
            </div>
          </div>

          <div class="table-responsive">
            <table
              id="printMe"
              class="table align-middle table-nowrap table-check"
            >
              <thead class="table-dark">
                <tr>
                  <th
                    v-if="hasMultiSelect"
                    style="width: 5%"
                    class="align-middle"
                  >
                    <div class="form-check font-size-16">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        v-model="selectAll"
                        @click="select"
                      />
                      <label class="form-check-label" for="checkAll"></label>
                    </div>
                  </th>

                  <th v-if="hasHashId" style="width: 5%" class="text-center">
                    #
                  </th>

                  <th
                    v-if="hasUser"
                    style="width: 5%"
                    class="align-middle font-weight-500 text-center"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ image }}
                  </th>

                  <th
                    v-if="hasCourseName"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ course_name }}
                  </th>
                  <th
                    v-if="hasCategory"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ category }}
                  </th>
                  <th
                    v-if="hasPrice || hasBalance"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ price || balance }}
                  </th>

                  <th
                    v-if="hasPaid"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.paid") }}
                  </th>

                  <th
                    v-if="hasDate"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.date") }}
                  </th>

                  <th
                    v-if="hasStatus"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.status") }}
                  </th>

                  <th
                    v-if="hasActions"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.actions") }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="dataLoading">
                <tr>
                  <td :colspan="colspan" class="text-center">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="!dataLoading && !items.length">
                <tr>
                  <td
                    :colspan="colspan"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <span>{{ $t("app.no_data") }}</span>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="!dataLoading && items.length">
                <tr v-for="(item, idx) in items" :key="idx">
                  <td v-if="hasMultiSelect">
                    <div class="form-check font-size-16">
                      <input
                        :id="'expBox' + item.id"
                        class="form-check-input"
                        type="checkbox"
                        v-model="selected"
                        :value="item.id"
                      />
                      <label class="form-check-label" :for="'expBox' + item.id">
                      </label>
                    </div>
                  </td>

                  <td
                    v-if="hasHashId"
                    class="text-center"
                    style="vertical-align: middle"
                  >
                    {{ idx + 1 }}
                  </td>

                  <td class="text-center" v-if="hasUser">
                    <img
                      v-if="item.user"
                      :src="item.user ? item.user.url : ''"
                      :style="image_style"
                      :title="item.user ? item.user.name : ''"
                      style="cursor: pointer"
                    />
                  </td>
                  <td
                    v-if="hasCourseName"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger text-center"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.course_name }}
                    </label>
                  </td>

                  <td
                    v-if="hasCategory"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger"
                      style="padding: 10px"
                      :class="
                        $i18n.locale == 'ar' ? 'font-size-13' : 'font-size-12'
                      "
                    >
                      {{ item.category_name }}
                    </label>
                  </td>

                  <td
                    v-if="hasPrice || hasBalance"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-secondary"
                      style="padding: 10px"
                      :class="
                        $i18n.locale == 'ar' ? 'font-size-13' : 'font-size-12'
                      "
                    >
                      {{ item.price || item.balance }} {{ $t("app.kw") }}
                    </label>
                  </td>

                  <td
                    v-if="hasPaid"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <span
                      v-if="item.paid"
                      class="badge badge-soft-info font-size-13"
                    >
                      {{ item.payment_method }}
                      <i
                        v-if="item.payment_method"
                        class="fa fa-check-circle font-size-16"
                      >
                      </i>
                    </span>
                    <span v-else>-</span>
                  </td>

                  <td
                    v-if="hasDate"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.date }}
                  </td>

                  <td
                    v-if="hasStatus"
                    class="text-center"
                    style="vertical-align: middle"
                  >
                    <label
                      v-if="item.status == 1"
                      class="badge rounded-pill badge-md badge-soft-success"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13 ml-1'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="
                        hasApproved ? $t('app.approved') : $t('app.active')
                      "
                    >
                    </label>
                    <label
                      v-if="item.status == 0"
                      class="badge rounded-pill badge-md badge-soft-primary"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="hasApproved ? $t('app.new') : $t('app.inactive')"
                    >
                    </label>
                    <label
                      v-if="item.status == 2"
                      class="badge rounded-pill badge-md badge-soft-danger"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="
                        hasApproved ? $t('app.unapproved') : $t('app.inactive')
                      "
                    >
                    </label>
                  </td>

                  <td v-if="hasActions" class="align-middle">
                    <div class="col-12 text-center">
                      <button
                        type="button"
                        v-if="!item.loading && !hasDeleteCourse"
                        @click="
                          item.loading = true;
                          deleteItem(item.encrypt_id);
                        "
                        class="btn btn-danger btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        :title="$t('role.delete')"
                      >
                        <i class="mdi mdi-delete font-size-17"></i>
                      </button>
                      <button
                        type="button"
                        v-if="hasDeleteCourse"
                        @click="
                          item.loading = true;
                          deleteCourse(
                            item.encrypt_id,
                            item.model_type,
                            item.user.encrypt_id
                          );
                        "
                        class="btn btn-danger btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        :title="$t('role.delete')"
                      >
                        <i class="mdi mdi-delete font-size-17"></i>
                      </button>
                      <span v-if="item.loading">
                        <span
                          class="spinner-grow spinner-grow-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        >
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p></p>
          <Pagination
            v-if="!dataLoading"
            :items="items"
            :pageCount="pageCount"
            v-on:pageChange="handelPageChange"
          >
          </Pagination>
        </div>
      </div>
    </div>

    <div class="col-4" v-if="hasBalance">
      <div class="card">
        <div class="card-body">
          <img
            v-if="user"
            :src="user.image ? user.image.url : ''"
            :style="image_style"
            :title="user ? user.name : ''"
            style="cursor: pointer"
          />

          <div class="form-group">
            <h4
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-18' : ''"
            >
              {{ user.name }}
            </h4>
          </div>
          <div class="form-group">
            <h4
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-18' : ''"
            >
              {{ $t("app.currentBalance") }}: {{ user.balance }}
            </h4>
          </div>
          <div class="form-group text-center">
            <span
              v-if="userLoading"
              class="spinner-grow spinner-grow-sm mr-1"
              role="status"
              aria-hidden="true"
            >
            </span>
            <input
              v-if="!userLoading"
              class="form-control"
              type="text"
              v-bind:value="new_balance"
              :placeholder="user.balance"
              :max="user.balance"
              @input="checkBalance"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            />

            <div class="col-md-12 form-group">
              <p><br /></p>
              <button
                v-if="!userLoading"
                type="button"
                :disabled="userLoading"
                @click="deposit"
                class="btn btn-primary"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                <span v-if="btn2Loading">
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  >
                  </span>
                </span>
                <i v-if="!btn2Loading" :class="'mdi mdi-plus-circle'"></i>&nbsp;
                <span v-if="!btn2Loading" v-html="$t('app.deposit')"></span>
              </button>
              &nbsp;&nbsp;
              <button
                v-if="!userLoading"
                type="button"
                :disabled="userLoading"
                @click="withdrawal"
                class="btn btn-danger"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                <span v-if="btnLoading">
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  >
                  </span>
                </span>
                <i v-if="!btnLoading" :class="'mdi mdi-minus-circle'"></i>&nbsp;
                <span v-if="!btnLoading" v-html="$t('app.withdrawal')"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-4" v-if="hasCourseName">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''"
            >
              {{ $t("app.choose_course") }}
            </label>
          </div>
          <div class="form-group text-center">
            <span
              v-if="courseLoading"
              class="spinner-grow spinner-grow-sm mr-1"
              role="status"
              aria-hidden="true"
            >
            </span>
            <select
              v-if="!courseLoading"
              class="form-control"
              v-model="course_id"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            >
              <option v-for="(co, idx) in courses" :key="idx" :value="co.id">
                {{ co[$i18n.locale]["title"] }}
              </option>
            </select>

            <div class="col-md-12 form-group">
              <p><br /></p>
              <button
                v-if="!courseLoading"
                type="button"
                :disabled="courseLoading"
                @click="editOrNew"
                class="btn btn-primary"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                <span v-if="btnLoading">
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  >
                  </span>
                </span>
                <i v-if="!btnLoading" :class="'mdi mdi-plus-circle'"></i>&nbsp;
                <span
                  v-if="!btnLoading"
                  v-html="$t('app.addCourseToUser')"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "Table",
  props: [
    "refs",
    "colspan",
    "user_id",
    "model_type",
    "locale",

    "hasMultiSelect",
    "hasBulkAction",
    "hasExport",
    "hasExportPDF",
    "hasShowEntries",
    "hasSearch",

    "hasSearchFilter",
    "hasDateFilter",
    "hasPaidFilter",
    "hasStatusApproved",
    "hasStatusFilter",

    "hasHashId",
    "lblSearch",

    "hasUser",
    "image",
    "image_style",
    "hasCourseName",
    "course_name",

    "hasPrice",
    "price",

    "hasCategory",
    "category",

    "hasBalance",
    "balance",

    "hasPaid",
    "hasDate",
    "hasStatus",
    "hasActions",
    "hasDeleteCourse",
    "hasShowEntry",

    "actionable",
    "sortable",
  ],
  components: {
    BulkAction: () => import("@/components/BulkAction.vue"),
    ShowEntries: () => import("@/components/ShowEntries.vue"),
    Pagination: () => import("@/components/Pagination.vue"),
  },
  data() {
    return {
      auth: {
        role: "",
        access_token: "",
      },

      fields: this.$props.fields,

      selected: [],
      selectAll: false,

      parent_id: "",
      slug: "",

      permissions: {
        add: false,
        edit: false,
        delete: false,
      },

      search: "",
      status: "",
      date: "",
      paid: "",
      filter_by: "",
      filter: "",
      order: "",
      order_by: "",
      role: this.$props.role_name ?? "",

      dataLoading: true,
      bulkLoading: true,
      exportLoading: true,
      orderLoading: false,
      authorLoading: false,

      courseLoading: true,
      userLoading: true,
      user: [],
      courses: [],
      course_id: "",
      new_balance: "",

      items: [],
      total: 0,
      page: 1,
      perPage: 10,
      pageCount: 10,

      isWithdrawl: false,

      btnLoading: false,
      btn2Loading: false,
    };
  },
  watch: {
    watchCountryId: function (new_val) {
      this.country_id = new_val;
      this.fetchData(true);
    },
    locale: function (newVal) {
      console.log(newVal);
      this.fetchData(true);
    },
  },
  mounted() {},
  created() {
    // accessToken
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }

    // fetchData
    this.fetchData();
    if (this.hasCourseName) this.fetchCourses();
  },
  methods: {
    fetchData(loading = false) {
      if (loading) {
        this.dataLoading = true;
      }
      (this.plural = ""), (this.selectAll = false);
      this.selected = [];
      this.isWithdrawl = false;
      this.new_balance = 0;

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      var url = "";
      if (this.hasCourseName)
        url =
          this.$props.refs +
          "/" +
          this.$props.model_type +
          "/" +
          this.$props.user_id;
      else if (this.hasBalance)
        url = this.$props.refs + "/" + this.$props.user_id + "/wallet";

      const options = {
        url: window.baseURL + "/" + url,
        method: "GET",
        data: {},
        params: {
          page: this.page,
          paginate: this.perPage,
          status: this.status,
          date: this.date,
          paid: this.paid,
          filter_by: this.filter_by,
          filter: this.filter,
          search: this.search,
          order: this.order,
          order_by: this.order_by,
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.bulkLoading = false;
          this.exportLoading = false;
          this.orderLoading = false;
          this.categoryLoading = false;
          this.authorLoading = false;
          //this.new_balance = 0;
          this.items = res.data.data.rows;
          this.total = res.data.data.paginate.total;

          if (res.data.data.paginate.total) {
            this.pageCount = Math.ceil(
              res.data.data.paginate.total / this.perPage
            );
          }

          if (this.hasBalance) {
            this.fetchUser();
          }
        })
        .catch((err) => {
          //403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    fetchUser() {
      this.userLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url: window.baseURL + "/users/" + this.$props.user_id,
        method: "GET",
      };
      this.axios(options)
        .then((res) => {
          this.userLoading = false;
          this.user = res.data.row;
        })
        .catch((err) => {
          this.userLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    fetchCourses() {
      this.courseLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url: window.baseURL + "/" + this.$props.model_type,
        method: "GET",
        data: {},
        params: {
          status: "active",
          paginate: 1000,
          lecturer_id: 1, // temporary it should fix later..
        },
      };
      this.axios(options)
        .then((res) => {
          this.courseLoading = false;
          this.courses = res.data.data.rows;
          this.course_id = res.data.data.rows.length
            ? res.data.data.rows[0]["id"]
            : null;
        })
        .catch((err) => {
          this.courseLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    // clear Local Storages
    clearLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("role");
    },

    clearDate() {
      this.date = "";
      this.fetchData();
    },

    // Handle Change from child components
    handelPageChange(event) {
      this.page = event;
      this.fetchData();
    },

    handelShowChange(event) {
      this.perPage = event;
      this.fetchData(true);
    },

    checkBalance(event) {
      const userBalance = Number(this.user.balance);
      let value = Number(event.target.value);
      if (this.isWithdrawl) {
        value = this.new_balance = value > userBalance ? userBalance : value;
        this.new_balance = value < 0 ? 0 : value;
      } else {
        this.new_balance = value;
      }
    },

    withdrawal() {
      this.btnLoading = true;
      this.isWithdrawl = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url:
          window.baseURL +
          "/" +
          this.$props.refs +
          "/" +
          this.$props.user_id +
          "/withdrawal",
        method: "POST",
        data: {
          balance: this.new_balance,
        },
      };
      this.axios(options)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم السحب بنجاح",
          });
          this.fetchData(true);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    deposit() {
      this.btn2Loading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url:
          window.baseURL +
          "/" +
          this.$props.refs +
          "/" +
          this.$props.user_id +
          "/deposit",
        method: "POST",
        data: {
          balance: this.new_balance,
        },
      };
      this.axios(options)
        .then(() => {
          this.btn2Loading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم الإضافة بنجاح",
          });
          this.fetchData(true);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btn2Loading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    editOrNew() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url:
          window.baseURL +
          "/" +
          this.$props.refs +
          "/" +
          this.$props.model_type +
          "/" +
          this.$props.user_id,
        method: "POST",
        data: {
          course_id: this.course_id,
        },
      };
      this.axios(options)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم الإضافة بنجاح",
          });
          this.fetchData(true);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    deleteItem(id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.$props.refs + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          })
          .finally(() => {});
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    deleteCourse(course_id, model_type, user_id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url:
            window.baseURL +
            "/" +
            this.$props.refs +
            "/" +
            model_type +
            "/" +
            user_id,
          method: "DELETE",
          data: {
            course_id,
          },
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          })
          .finally(() => {});
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    editStatusItem(id, status) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.$props.refs + "/" + id,
        method: "PATCH",
        data: {
          status: status,
        },
      };
      this.axios(options)
        .then(() => {
          this.fetchData(true);
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم التعديل بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData(true);
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    exportPDFItem(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.$props.refs + "/export/pdf/" + id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.fetchData(true);
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select,
option {
  font-family: "hacen_algeria" !important;
}
</style>